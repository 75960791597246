
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Cognito from "@/plugins/cognito";
import MemberService from "@/services/members";

@Component({
  name: "ResetPasswordSend",
  components: {
    Header,
    Footer,
  },
})
export default class ResetPasswordSend extends Vue {
  private email = "";

  private loading = false;
  private showLimitChangeError = false;

  private showLimitError() {
    this.showLimitChangeError = true;
    setTimeout(() => {
      this.showLimitChangeError = false;
    }, 3000);
  }

  private async submit() {
    try {
      this.loading = true;
      await Cognito.forgetPassword(this.email);
      await MemberService.sendResetPasswordLink(this.email);
      this.$router.push({ name: "ResetPasswordSendConfirmed" });
      this.loading = false;
    } catch (error: any) {
      if (error.message === "Attempt limit exceeded, please try after some time.") {
        this.showLimitError();
      }
      this.loading = false;
      throw new Error(error);
    }
  }
}
